import * as TYPES from './types';

// Get Clients
export const getClientsRequest = (payload) => ({
    type: TYPES.GET_CLIENTS_REQUEST,
    payload
});
export const getClientsReceive = (payload) => ({
    type: TYPES.GET_CLIENTS_RECEIVE,
    payload
});
//Get Client
export const getClienRequest = (id) => ({
    type: TYPES.GET_CLIENT_REQUEST,
    id
});
export const getClientReceive = (payload) => ({
    type: TYPES.GET_CLIENT_RECEIVE,
    payload
});
//Add Client
export const addClientRequest = (payload, navigate) => ({
    type: TYPES.ADD_CLIENT_REQUEST,
    payload,
    navigate
});
export const addClientReceive = (payload) => ({
    type: TYPES.ADD_CLIENT_RECEIVE,
    payload
});
//Edit Client
export const editClientRequest = (payload, navigate) => ({
    type: TYPES.EDIT_CLIENT_REQUEST,
    payload,
    navigate
});
export const editClientReceive = (payload) => ({
    type: TYPES.EDIT_CLIENT_RECEIVE,
    payload
});
//Delete Client
export const deleteClientRequest = (id) => ({
    type: TYPES.DELETE_CLIENT_REQUEST,
    id
});
export const deleteClientReceive = (id) => ({
    type: TYPES.DELETE_CLIENT_RECEIVE,
    id
});
//Get Clients List
export const getClientsListRequest = (payload) => ({
    type: TYPES.GET_CLIENTS_LIST_REQUEST,
    payload
});
export const getClientsListReceive = (payload) => ({
    type: TYPES.GET_CLIENTS_LIST_RECEIVE,
    payload
});
//Get Clients Statistics
export const getClientsStatisticsRequest = (payload) => ({
    type: TYPES.GET_CLIENTS_STATISTICS_REQUEST,
    payload
});
export const getClientsStatisticsReceive = (payload) => ({
    type: TYPES.GET_CLIENTS_STATISTICS_RECEIVE,
    payload
});
