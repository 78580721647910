import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Requests
export function* getRequestsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getRequestsRequest, payload);
        yield put(ACTIONS.getRequestsReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        alert("aaaaaaa")
        console.log(err?.response?.data);
        //dispatchSnackbarError(err.response?.data);
    }
}

//Get Request
export function* getRequestSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getRequestRequest, id);
        yield put(ACTIONS.getRequestReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        //dispatchSnackbarError(err.response?.data);
    }
}

//Add Request
export function* addRequestSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.addRequestRequest, payload);
        yield put(ACTIONS.addRequestReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 1000);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit Request
export function* editRequestSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editRequestRequest, payload);
        yield put(ACTIONS.editRequestReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 1000);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Request
export function* deleteRequestSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteRequestRequest, id);
        yield put(ACTIONS.deleteRequestReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Requests List
export function* getRequestsListSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getRequestsListRequest, payload);
        yield put(ACTIONS.getRequestsListReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
       // dispatchSnackbarError(err.response?.data);
    }
}

//Get Requests Statistics
export function* getRequestsStatisticsSaga({ payload }) {
    try {
        const { data } = yield call(APIS.getRequestsStatisticsRequest, payload);
        yield put(ACTIONS.getRequestsStatisticsReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Affect Professional
export function* affectProfessionalSaga({ payload }) {
    try {
        const { data } = yield call(APIS.affectProfessionalRequest, payload);
        yield put(ACTIONS.affectProfessionalReceive(data));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Check Discount Code
export function* checkDiscountCodeSaga({ payload }) {
    try {
        yield put(ACTIONS.checkDiscountCodeReceive(false));
        const { data } = yield call(APIS.checkDiscountCodeRequest, payload);
        if (data && data.value) yield put(ACTIONS.checkDiscountCodeReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        yield put(ACTIONS.checkDiscountCodeReceive(false));
        dispatchSnackbarError(err.response?.data);
    }
}

export function* requestsSagas() {
    yield takeLatest(TYPES.GET_REQUESTS_REQUEST, getRequestsSaga);
    yield takeLatest(TYPES.GET_REQUEST_REQUEST, getRequestSaga);
    yield takeLatest(TYPES.ADD_REQUEST_REQUEST, addRequestSaga);
    yield takeLatest(TYPES.EDIT_REQUEST_REQUEST, editRequestSaga);
    yield takeLatest(TYPES.DELETE_REQUEST_REQUEST, deleteRequestSaga);
    yield takeLatest(TYPES.GET_REQUESTS_LIST_REQUEST, getRequestsListSaga);
    yield takeLatest(TYPES.GET_REQUESTS_STATISTICS_REQUEST, getRequestsStatisticsSaga);
    yield takeLatest(TYPES.AFFECT_PROFESSIONAL_REQUEST, affectProfessionalSaga);
    yield takeLatest(TYPES.CHECK_DISCOUNT_CODE_REQUEST, checkDiscountCodeSaga);
}
