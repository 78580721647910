 
import { Box, Stack, TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function FilterContainer({ payload, setPayload }) {
    const { search } = payload;
    const handleSearch = (e) => {
        setPayload({
            search: e.target.value
        });
    };

    return (
        <Stack>
            <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
                <TextField
                    label="Rechercher"
                    name="search"
                    onChange={handleSearch}
                    value={search}
                    variant="outlined"
                    sx={{ width: '250px' }}
                />
            </Box>
        </Stack>
    );
}

FilterContainer.propTypes = {
    payload: PropTypes.object.isRequired,
    setPayload: PropTypes.func.isRequired
};

export default FilterContainer;
