// assets
import { IconDashboard, IconCoinEuro, IconAd2, IconAdOff, IconChecklist, IconTools } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconCoinEuro, IconAd2, IconAdOff, IconChecklist, IconTools };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Swilo App',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'requests',
            title: 'Demandes',
            type: 'item',
            url: '/requests',
            icon: icons.IconAd2,
            breadcrumbs: true
        },
        {
            id: 'projets',
            title: 'Projets',
            type: 'item',
            url: '/projects',
            icon: icons.IconChecklist,
            breadcrumbs: true
        },
        {
            id: 'payments',
            title: 'Paiements',
            type: 'item',
            url: '/payments',
            icon: icons.IconCoinEuro,
            breadcrumbs: true
        },
        {
            id: 'materials',
            title: 'Matériels',
            type: 'item',
            url: '/tools',
            icon: icons.IconTools,
            breadcrumbs: true
        },
        {
            id: 'discounts',
            title: 'Promotions',
            type: 'item',
            url: '/discounts',
            icon: icons.IconAdOff,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
