import * as TYPES from './types';

// Get Requests
export const getRequestsRequest = (payload) => ({
    type: TYPES.GET_REQUESTS_REQUEST,
    payload
});
export const getRequestsReceive = (payload) => ({
    type: TYPES.GET_REQUESTS_RECEIVE,
    payload
});
//Get Request
export const getRequestRequest = (id) => ({
    type: TYPES.GET_REQUEST_REQUEST,
    id
});
export const getRequestReceive = (payload) => ({
    type: TYPES.GET_REQUEST_RECEIVE,
    payload
});
//Add Request
export const addRequestRequest = (payload, closeModal) => ({
    type: TYPES.ADD_REQUEST_REQUEST,
    payload,
    closeModal
});
export const addRequestReceive = (payload) => ({
    type: TYPES.ADD_REQUEST_RECEIVE,
    payload
});
//Edit Request
export const editRequestRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_REQUEST_REQUEST,
    payload,
    closeModal
});
export const editRequestReceive = (payload) => ({
    type: TYPES.EDIT_REQUEST_RECEIVE,
    payload
});
//Delete Request
export const deleteRequestRequest = (id) => ({
    type: TYPES.DELETE_REQUEST_REQUEST,
    id
});
export const deleteRequestReceive = (id) => ({
    type: TYPES.DELETE_REQUEST_RECEIVE,
    id
});
//Get Requests List
export const getRequestsListRequest = (payload) => ({
    type: TYPES.GET_REQUESTS_LIST_REQUEST,
    payload
});
export const getRequestsListReceive = (payload) => ({
    type: TYPES.GET_REQUESTS_LIST_RECEIVE,
    payload
});
//Get Requests Statistics
export const getRequestsStatisticsRequest = (payload) => ({
    type: TYPES.GET_REQUESTS_STATISTICS_REQUEST,
    payload
});
export const getRequestsStatisticsReceive = (payload) => ({
    type: TYPES.GET_REQUESTS_STATISTICS_RECEIVE,
    payload
});
//Affect Proffessional
export const affectProfessionalRequest = (payload) => ({
    type: TYPES.AFFECT_PROFESSIONAL_REQUEST,
    payload
});
export const affectProfessionalReceive = (payload) => ({
    type: TYPES.AFFECT_PROFESSIONAL_RECEIVE,
    payload
});
//Check Discount Code
export const checkDiscountCodeRequest = (payload) => ({
    type: TYPES.CHECK_DISCOUNT_CODE_REQUEST,
    payload
});
export const checkDiscountCodeReceive = (payload) => ({
    type: TYPES.CHECK_DISCOUNT_CODE_RECEIVE,
    payload
});
