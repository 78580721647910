 
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Grid, InputLabel, OutlinedInput } from '@mui/material';
import { gridSpacing } from 'utils/Constants';
import moment from 'moment';

function FormContainer({ formData, handleSetFormData }) {
    return (
        <React.Fragment>
            <form autoComplete="off" style={{ padding: '20px 0' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="code">Code</InputLabel>
                            <OutlinedInput
                                id="code"
                                name="code"
                                type="text"
                                value={formData.code}
                                onChange={(e) => handleSetFormData(e)}
                                label="Code"
                                autoComplete="off"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="value">Valeur</InputLabel>
                            <OutlinedInput
                                id="value"
                                name="value"
                                type="number"
                                value={formData.value}
                                onChange={(e) => handleSetFormData(e)}
                                label="Valeur"
                                autoComplete="off"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                                id="expirationDate"
                                name="expirationDate"
                                type="datetime-local"
                                value={moment(formData.expirationDate).format('YYYY-MM-DDTHH:mm')}
                                onChange={(e) => handleSetFormData(e)}
                                label="Date d'éxpiration"
                                autoComplete="off"
                                inputProps={{
                                    min: moment().format('YYYY-MM-DDTHH:mm')
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </React.Fragment>
    );
}

FormContainer.propTypes = {
    formData: PropTypes.object.isRequired,
    handleSetFormData: PropTypes.func.isRequired
};

export default FormContainer;
