//const  myBaseUrl = "http://128.199.32.128/";

import moment from 'moment';

//const myBaseUrl = "http://localhost:3000/";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const IS_TN = process.env.REACT_APP_IS_TN === 'true' ?? true;

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 275;
export const appDrawerWidth = 320;
// USERS ROLES
const USER = 'USER';
const ADMIN = 'ADMIN';
const PROFESSIONAL = 'PROFESSIONAL';
const CUSTOMER = 'CUSTOMER';
// USERS STATUS
const ACTIVE = 'ACTIVE';
const BLOCKED = 'BLOCKED';
//const INACTIVE = 'INACTIVE';
const DELETED = 'DELETED';

// TOOLS STATUS
const AVAILABLE = 'AVAILABLE';
const UNAVAILABLE = 'UNAVAILABLE';
const INACTIVE = 'INACTIVE';

//Requests Status
const NEW = 'NEW';
const PENDING = 'PENDING';
const PLANED = 'PLANED';
const REJECTED = 'REJECTED';
const CANCELED = 'CANCELED';
const DONE = 'DONE';

//Requests Types
const IMMOBILIER = 'IMMOBILIER';
const TAPIS = 'TAPIS';
const MATELAS = 'MATELAS';
const AUTO = 'AUTO';

//Options Types
const SELECT = 'SELECT';
const SURFACE = 'SURFACE';
const NBPLACE = 'NBPLACE';
const NBPLACE_SURFACE = 'NBPLACE_SURFACE';
const TYPE_NBPLACE_SURFACE = 'TYPE_NBPLACE_SURFACE';

// Payment Types
const IN = 'IN';
const OUT = 'OUT';

// Payment Status
const PAID = 'PAID';

export const usersRoles = [
    { id: USER, name: 'Utilisateur' },
    { id: ADMIN, name: 'Administrateur' },
    { id: PROFESSIONAL, name: 'Professionnel' },
    { id: CUSTOMER, name: 'Client' }
];

export const usersStatus = [
    { id: ACTIVE, name: 'Actif' },
    { id: BLOCKED, name: 'Bloqué' },
    { id: INACTIVE, name: 'Inactif' },
    { id: DELETED, name: 'Supprimé' }
];

export const toolsStatus = [
    { id: AVAILABLE, name: 'Disponible' },
    { id: UNAVAILABLE, name: 'Indisponible' },
    { id: INACTIVE, name: 'Inactif' }
];

export const requestsStatus = [
    { id: NEW, name: 'Nouveau' },
    { id: PENDING, name: 'En attente' },
    { id: PLANED, name: 'Planifié' },
    { id: REJECTED, name: 'Rejeté' },
    { id: CANCELED, name: 'Annulé' },
    { id: DONE, name: 'Terminé' }
];

export const requestsTypes = [
    { id: IMMOBILIER, name: 'Immobilier' },
    { id: TAPIS, name: 'Tapis' },
    { id: MATELAS, name: 'Matelas' },
    { id: AUTO, name: 'Véhicule' }
];

export const optionsTypes = [
    { id: SELECT, name: 'Sélection (Titre / Prix)' },
    { id: SURFACE, name: 'Surface (Longueur * Largeur / Prix)' },
    { id: NBPLACE, name: 'Nombre de place (Nombre de place / Prix)' },
    { id: NBPLACE_SURFACE, name: 'Nombre de place et surface (Nombre de place / Surface / Prix)' },
    { id: TYPE_NBPLACE_SURFACE, name: 'Type et nombre de place et surface (Type / Nombre de place / Prix)' }
];

export const projectsStatus = [{ id: DONE, name: 'Projets fini' }];

export const paymentStatus = [
    { id: PAID, name: 'Payé' },
    { id: PENDING, name: 'En attente' },
    { id: CANCELED, name: 'Annulé' }
];

export const paymentTypes = [
    { id: IN, name: 'Entrée' },
    { id: OUT, name: 'Sortie' }
];

export const initialAddress = {
    id: null,
    address: '',
    lat: '',
    lng: ''
};

export const initialImage = {
    id: null,
    filename: '',
    mimetype: '',
    encoding: ''
};

export const initialUser = {
    id: null,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    address: initialAddress,
    role: '',
    status: ACTIVE,
    isVerified: true,
    otpVerified: true,
    image: initialImage,
    hasTools: false,
    siret: '',
    campany: ''
};

export const initialDemande = {
    id: null,
    title: '',
    date: moment().format('YYYY-MM-DDTHH:MM'),
    notes: '',
    address: initialAddress,
    requestItems: [
        {
            id: null,
            image: initialImage,
            serviceId: '0',
            optionId: '0',
            variableId: '0',
            quantity: 1,
            dimension: 1
        }
    ],
    customerId: '0',
    professionalId: null,
    source: 'WEB',
    discountVerified: false,
    discountCode: '',
    price: 0,
    priceAfterDiscount: 0
};

export const initialPayment = {
    id: null,
    type: 'OUT',
    date: moment().format('YYYY-MM-DDTHH:MM'),
    amount: 0,
    notes: '',
    transactionId: '',
    professionalId: '0',
    customerId: '0',
    status: 'PAID',
    username: ''
};

export const initialDiscount = {
    id: null,
    code: '',
    value: 0,
    expirationDate: moment().format('YYYY-MM-DDTHH:MM')
};

export const initialTool = {
    id: null,
    title: '',
    notes: '',
    address: initialAddress,
    status: AVAILABLE
};

export const initialService = {
    id: null,
    title: '',
    description: '',
    icon: ''
};

export const initialOption = {
    id: null,
    title: '',
    serviceId: '0',
    isCategory: false,
    isInput: false
};

export const initialVariable = {
    id: null,
    title: '',
    minSurface: 0,
    maxSurface: 0,
    price: 0,
    optionId: '0'
};

export const optionTypes = [
    { id: 1, name: 'Surface' },
    { id: 2, name: 'Type de canapé' },
    { id: 3, name: 'Nombre de places' },
    { id: 4, name: 'Type de prestation' },
    { id: 5, name: 'Model de voiture' },
    { id: 6, name: 'Immatriculation' }
];

export const encodeUserNameAndPass = (userName, password) => {
    var decodedStringBtoA = `[userName]${userName}[password]${password}`;
    return btoa(decodedStringBtoA);
};

export const decodeUserNameAndPass = (str) => {
    return atob(str);
};

export const fileSupportedFormats = ['image/JPEG', 'image/JPG', 'image/jpg', 'image/png', 'image/PNG', 'image/jpeg'];

export const arabicMonths = ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر'];

export const arabicDays = ['الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعة', 'السبت'];
