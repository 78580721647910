import * as TYPES from './types';

const initialState = {
    variables: [],
    variable: {}
};

export default function variablesReducer(state = initialState, action) {
    switch (action.type) {
        //Get variables
        case TYPES.GET_VARIABLES_RECEIVE: {
            return {
                ...state,
                variables: action.payload.data
            };
        }
        //Get variable
        case TYPES.GET_VARIABLE_RECEIVE: {
            return {
                ...state,
                variable: action.payload
            };
        }
        //Create variable
        case TYPES.CREATE_VARIABLE_RECEIVE: {
            console.log(action.payload);
            return {
                ...state,
                variables: [...state.variables, action.payload]
            };
        }
        //Update variable
        case TYPES.UPDATE_VARIABLE_RECEIVE: {
            return {
                ...state,
                variables: state.variables.map((variable) => {
                    if (variable.id === action.payload.id) {
                        return action.payload;
                    }
                    return variable;
                })
            };
        }
        //Delete variable
        case TYPES.DELETE_VARIABLE_RECEIVE: {
            return {
                ...state,
                variables: state.variables.filter((variable) => variable.id !== action.id)
            };
        }
        default:
            return state;
    }
}
