import * as TYPES from './types';
//Discounts
// Get Discounts
export const getDiscountsRequest = (payload) => ({
    type: TYPES.GET_DISCOUNTS_REQUEST,
    payload
});
export const getDiscountsReceive = (payload) => ({
    type: TYPES.GET_DISCOUNTS_RECEIVE,
    payload
});
//Get Discount
export const getDiscountRequest = (payload) => ({
    type: TYPES.GET_DISCOUNT_REQUEST,
    payload
});
export const getDiscountReceive = (payload) => ({
    type: TYPES.GET_DISCOUNT_RECEIVE,
    payload
});
//Create Discount
export const createDiscountRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_DISCOUNT_REQUEST,
    payload,
    closeModal
});
export const createDiscountReceive = (payload) => ({
    type: TYPES.CREATE_DISCOUNT_RECEIVE,
    payload
});
//Update Discount
export const updateDiscountRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_DISCOUNT_REQUEST,
    payload,
    closeModal
});
export const updateDiscountReceive = (payload) => ({
    type: TYPES.UPDATE_DISCOUNT_RECEIVE,
    payload
});
//Delete Discount
export const deleteDiscountRequest = (id) => ({
    type: TYPES.DELETE_DISCOUNT_REQUEST,
    id
});
export const deleteDiscountReceive = (id) => ({
    type: TYPES.DELETE_DISCOUNT_RECEIVE,
    id
});
