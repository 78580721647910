//Options
//Get Options
export const GET_OPTIONS_REQUEST = 'GET_OPTIONS_REQUEST';
export const GET_OPTIONS_RECEIVE = 'GET_OPTIONS_RECEIVE';
//Get Option
export const GET_OPTION_REQUEST = 'GET_OPTION_REQUEST';
export const GET_OPTION_RECEIVE = 'GET_OPTION_RECEIVE';
//Create Option
export const CREATE_OPTION_REQUEST = 'CREATE_OPTION_REQUEST';
export const CREATE_OPTION_RECEIVE = 'CREATE_OPTION_RECEIVE';
//Update Option
export const UPDATE_OPTION_REQUEST = 'UPDATE_OPTION_REQUEST';
export const UPDATE_OPTION_RECEIVE = 'UPDATE_OPTION_RECEIVE';
//Delete Option
export const DELETE_OPTION_REQUEST = 'DELETE_OPTION_REQUEST';
export const DELETE_OPTION_RECEIVE = 'DELETE_OPTION_RECEIVE';
