import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Projects
//Get Projects
export function* getProjectsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getProjectsRequest, payload);
        yield put(ACTIONS.getProjectsReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Project
export function* getProjectSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getProjectRequest, payload);
        yield put(ACTIONS.getProjectReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Project
export function* createProjectSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createProjectRequest, payload);
        yield put(ACTIONS.createProjectReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Project
export function* updateProjectSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateProjectRequest, payload);
        yield put(ACTIONS.updateProjectReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Project
export function* deleteProjectSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteProjectRequest, id);
        yield put(ACTIONS.deleteProjectReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* projectsSagas() {
    yield takeLatest(TYPES.GET_PROJECTS_REQUEST, getProjectsSaga);
    yield takeLatest(TYPES.GET_PROJECT_REQUEST, getProjectSaga);
    yield takeLatest(TYPES.CREATE_PROJECT_REQUEST, createProjectSaga);
    yield takeLatest(TYPES.UPDATE_PROJECT_REQUEST, updateProjectSaga);
    yield takeLatest(TYPES.DELETE_PROJECT_REQUEST, deleteProjectSaga);
}
