import { axiosInstance } from '../../network';
//Projects
//Get Projects
export const getProjectsRequest = async (payload) => {
    return await axiosInstance.get(`api/requests/projects`, { params: payload });
};
//Get Project
export const getProjectRequest = async (payload) => {
    return await axiosInstance.get(`api/requests/${payload}`);
};
//Create Project
export const createProjectRequest = async (payload) => {
    return await axiosInstance.post(`api/requests`, payload);
};
//Update Project
export const updateProjectRequest = async (payload) => {
    return await axiosInstance.patch(`api/requests/${payload.id}`, payload);
};
//Delete Project
export const deleteProjectRequest = async (id) => {
    return await axiosInstance.delete(`api/requests/${id}`);
};
