import { axiosInstance } from '../../network';
//Options
//Get Options
export const getOptionsRequest = async (payload) => {
    return await axiosInstance.get(`api/options`, { params: payload });
};
//Get Option
export const getOptionRequest = async (payload) => {
    return await axiosInstance.get(`api/options/${payload}`);
};
//Create Option
export const createOptionRequest = async (payload) => {
    return await axiosInstance.post(`api/options`, payload);
};
//Update Option
export const updateOptionRequest = async (payload) => {
    return await axiosInstance.patch(`api/options/${payload.id}`, payload);
};
//Delete Option
export const deleteOptionRequest = async (id) => {
    return await axiosInstance.delete(`api/options/${id}`);
};
