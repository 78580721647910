import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Tools
//Get Tools
export function* getToolsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getToolsRequest, payload);
        yield put(ACTIONS.getToolsReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Tool
export function* getToolSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getToolRequest, payload);
        yield put(ACTIONS.getToolReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Tool
export function* createToolSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createToolRequest, payload);
        yield put(ACTIONS.createToolReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Tool
export function* updateToolSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateToolRequest, payload);
        yield put(ACTIONS.updateToolReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Tool
export function* deleteToolSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteToolRequest, id);
        yield put(ACTIONS.deleteToolReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* toolsSagas() {
    yield takeLatest(TYPES.GET_TOOLS_REQUEST, getToolsSaga);
    yield takeLatest(TYPES.GET_TOOL_REQUEST, getToolSaga);
    yield takeLatest(TYPES.CREATE_TOOL_REQUEST, createToolSaga);
    yield takeLatest(TYPES.UPDATE_TOOL_REQUEST, updateToolSaga);
    yield takeLatest(TYPES.DELETE_TOOL_REQUEST, deleteToolSaga);
}
