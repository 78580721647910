import * as TYPES from './types';

const initialState = {
    tools: [],
    tool: {}
};

export default function toolsReducer(state = initialState, action) {
    switch (action.type) {
        //Get tools
        case TYPES.GET_TOOLS_RECEIVE: {
            return {
                ...state,
                tools: action.payload.data
            };
        }
        //Get tool
        case TYPES.GET_TOOL_RECEIVE: {
            return {
                ...state,
                tool: action.payload
            };
        }
        //Create tool
        case TYPES.CREATE_TOOL_RECEIVE: {
            console.log(action.payload);
            return {
                ...state,
                tools: [...state.tools, action.payload]
            };
        }
        //Update tool
        case TYPES.UPDATE_TOOL_RECEIVE: {
            return {
                ...state,
                tools: state.tools.map((tool) => {
                    if (tool.id === action.payload.id) {
                        return action.payload;
                    }
                    return tool;
                })
            };
        }
        //Delete tool
        case TYPES.DELETE_TOOL_RECEIVE: {
            return {
                ...state,
                tools: state.tools.filter((tool) => tool.id !== action.id)
            };
        }
        default:
            return state;
    }
}
