import { axiosInstance } from '../../network';
//Variables
//Get Variables
export const getVariablesRequest = async (payload) => {
    return await axiosInstance.get(`api/variables`, { params: payload });
};
//Get Variable
export const getVariableRequest = async (payload) => {
    return await axiosInstance.get(`api/variables/${payload}`);
};
//Create Variable
export const createVariableRequest = async (payload) => {
    return await axiosInstance.post(`api/variables`, payload);
};
//Update Variable
export const updateVariableRequest = async (payload) => {
    return await axiosInstance.patch(`api/variables/${payload.id}`, payload);
};
//Delete Variable
export const deleteVariableRequest = async (id) => {
    return await axiosInstance.delete(`api/variables/${id}`);
};
