//Professionals
//Get Professionals
export const GET_PROFESSIONALS_REQUEST = 'GET_PROFESSIONALS_REQUEST';
export const GET_PROFESSIONALS_RECEIVE = 'GET_PROFESSIONALS_RECEIVE';
//Get Professional
export const GET_PROFESSIONAL_REQUEST = 'GET_PROFESSIONAL_REQUEST';
export const GET_PROFESSIONAL_RECEIVE = 'GET_PROFESSIONAL_RECEIVE';
//Create Professional
export const CREATE_PROFESSIONAL_REQUEST = 'CREATE_PROFESSIONAL_REQUEST';
export const CREATE_PROFESSIONAL_RECEIVE = 'CREATE_PROFESSIONAL_RECEIVE';
//Update Professional
export const UPDATE_PROFESSIONAL_REQUEST = 'UPDATE_PROFESSIONAL_REQUEST';
export const UPDATE_PROFESSIONAL_RECEIVE = 'UPDATE_PROFESSIONAL_RECEIVE';
//Delete Professional
export const DELETE_PROFESSIONAL_REQUEST = 'DELETE_PROFESSIONAL_REQUEST';
export const DELETE_PROFESSIONAL_RECEIVE = 'DELETE_PROFESSIONAL_RECEIVE';
// Get Professionals List
export const GET_PROFESSIONALS_LIST_REQUEST = 'GET_PROFESSIONALS_LIST_REQUEST';
export const GET_PROFESSIONALS_LIST_RECEIVE = 'GET_PROFESSIONALS_LIST_RECEIVE';
// Active Professional Account
export const ACTIVE_PROFESSIONAL_ACCOUNT_REQUEST = 'ACTIVE_PROFESSIONAL_ACCOUNT_REQUEST';
export const ACTIVE_PROFESSIONAL_ACCOUNT_RECEIVE = 'ACTIVE_PROFESSIONAL_ACCOUNT_RECEIVE';
