import { IS_TN } from './Constants';

const Formatter = (price) => {
    return IS_TN ? `${price.toFixed(3)} DT` : `€${price.toFixed(2)}`;
};

const FormatterShortForm = (price) => {
    return IS_TN ? `${price} DT` : `€${price}`;
};

export const FormatPrice = (price, shortForm = false) => {
    if (isNaN(price)) return shortForm ? FormatterShortForm(0.0) : Formatter(0.0);
    const Price = Number(price);
    return shortForm ? FormatterShortForm(Price) : Formatter(Price);
};
