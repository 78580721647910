import * as TYPES from './types';

const initialState = {
    professionals: [],
    professional: {},
    professionalsList: []
};

export default function professionalsReducer(state = initialState, action) {
    switch (action.type) {
        //Get professionals
        case TYPES.GET_PROFESSIONALS_RECEIVE: {
            return {
                ...state,
                professionals: action.payload?.data?.data,
                pageCount: action.payload?.data?.total / 10,
                totalFiltred: action.payload?.data?.total,
                currentPage: action.payload?.data?.page
            };
        }
        //Get professional
        case TYPES.GET_PROFESSIONAL_RECEIVE: {
            return {
                ...state,
                professional: action.payload
            };
        }
        //Create professional
        case TYPES.CREATE_PROFESSIONAL_RECEIVE: {
            console.log(action.payload);
            return {
                ...state,
                professionals: [...state.professionals, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Update professional
        case TYPES.UPDATE_PROFESSIONAL_RECEIVE: {
            return {
                ...state,
                professionals: state.professionals.map((professional) => {
                    if (professional.id === action.payload.id) {
                        return action.payload;
                    }
                    return professional;
                })
            };
        }
        //Delete professional
        case TYPES.DELETE_PROFESSIONAL_RECEIVE: {
            return {
                ...state,
                professionals: state.professionals.filter((professional) => professional.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        //Get professionals list
        case TYPES.GET_PROFESSIONALS_LIST_RECEIVE: {
            return {
                ...state,
                professionalsList: action.payload
            };
        }
        //Active professional account
        case TYPES.ACTIVE_PROFESSIONAL_ACCOUNT_RECEIVE: {
            return {
                ...state,
                professionals: state.professionals.map((professional) => {
                    if (professional.id === action.payload.id) {
                        return action.payload;
                    }
                    return professional;
                })
            };
        }
        default:
            return state;
    }
}
