import * as TYPES from './types';

const initialState = {
    customers: [],
    customer: {},
    pageCount: 0,
    totalFiltred: 0,
    currentPage: 0,
    customersList: []
};

export default function customersReducer(state = initialState, action) {
    switch (action.type) {
        //Get customers
        case TYPES.GET_CUSTOMERS_RECEIVE: {
            return {
                ...state,
                customers: action.payload?.data?.data,
                pageCount: action.payload?.data?.total / 10,
                totalFiltred: action.payload?.data?.total,
                currentPage: action.payload?.data?.page
            };
        }
        //Get customer
        case TYPES.GET_CUSTOMER_RECEIVE: {
            return {
                ...state,
                customer: action.payload
            };
        }
        //Create customer
        case TYPES.CREATE_CUSTOMER_RECEIVE: {
            console.log(action.payload);
            return {
                ...state,
                customers: [...state.customers, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Update customer
        case TYPES.UPDATE_CUSTOMER_RECEIVE: {
            return {
                ...state,
                customers: state.customers.map((customer) => {
                    if (customer.id === action.payload.id) {
                        return action.payload;
                    }
                    return customer;
                })
            };
        }
        //Delete customer
        case TYPES.DELETE_CUSTOMER_RECEIVE: {
            return {
                ...state,
                customers: state.customers.filter((customer) => customer.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        //Get customers list
        case TYPES.GET_CUSTOMERS_LIST_RECEIVE: {
            return {
                ...state,
                customersList: action.payload
            };
        }
        default:
            return state;
    }
}
