import { axiosInstance } from '../../network';
//Discounts
//Get Discounts
export const getDiscountsRequest = async (payload) => {
    return await axiosInstance.get(`api/discounts`, { params: payload });
};
//Get Discount
export const getDiscountRequest = async (payload) => {
    return await axiosInstance.get(`api/discounts/${payload}`);
};
//Create Discount
export const createDiscountRequest = async (payload) => {
    return await axiosInstance.post(`api/discounts`, payload);
};
//Update Discount
export const updateDiscountRequest = async (payload) => {
    return await axiosInstance.patch(`api/discounts/${payload.id}`, payload);
};
//Delete Discount
export const deleteDiscountRequest = async (id) => {
    return await axiosInstance.delete(`api/discounts/${id}`);
};
