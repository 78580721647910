import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Variables
//Get Variables
export function* getVariablesSaga({ payload }) {
    try {
        //yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getVariablesRequest, payload);
        yield put(ACTIONS.getVariablesReceive({ data }));
        //yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Variable
export function* getVariableSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getVariableRequest, payload);
        yield put(ACTIONS.getVariableReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Variable
export function* createVariableSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createVariableRequest, payload);
        yield put(ACTIONS.createVariableReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Variable
export function* updateVariableSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateVariableRequest, payload);
        yield put(ACTIONS.updateVariableReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Variable
export function* deleteVariableSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteVariableRequest, id);
        yield put(ACTIONS.deleteVariableReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* variablesSagas() {
    yield takeLatest(TYPES.GET_VARIABLES_REQUEST, getVariablesSaga);
    yield takeLatest(TYPES.GET_VARIABLE_REQUEST, getVariableSaga);
    yield takeLatest(TYPES.CREATE_VARIABLE_REQUEST, createVariableSaga);
    yield takeLatest(TYPES.UPDATE_VARIABLE_REQUEST, updateVariableSaga);
    yield takeLatest(TYPES.DELETE_VARIABLE_REQUEST, deleteVariableSaga);
}
