import * as TYPES from './types';

const initialState = {
    clients: [],
    client: {},
    clientsStatistics: {},
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    clientsList: []
};

export default function commentsReducer(state = initialState, action) {
    switch (action.type) {
        //Get clients
        case TYPES.GET_CLIENTS_RECEIVE: {
            return {
                ...state,
                clients: action.payload?.data?.data,
                pageCount: action.payload?.data?.total / 10,
                totalFiltred: action.payload?.data?.total,
                currentPage: action.payload?.data?.page
            };
        }
        //Get clientData
        case TYPES.GET_CLIENT_RECEIVE: {
            return {
                ...state,
                clientData: action.payload
            };
        }
        //Add client
        case TYPES.ADD_CLIENT_RECEIVE: {
            return {
                ...state,
                clients: [...state.clients, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit client
        case TYPES.EDIT_CLIENT_RECEIVE: {
            return {
                ...state,
                client: action.payload,
                clients: state.clients.map((client) => {
                    if (client.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return client;
                    }
                })
            };
        }
        //Delete client
        case TYPES.DELETE_CLIENT_RECEIVE: {
            return {
                ...state,
                clients: state.clients.filter((client) => client.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        //Get clients List
        case TYPES.GET_CLIENTS_LIST_RECEIVE: {
            return {
                ...state,
                clientsList: action.payload
            };
        }

        //Get clients Statistics
        case TYPES.GET_CLIENTS_STATISTICS_RECEIVE: {
            return {
                ...state,
                clientsStatistics: action.payload
            };
        }
        default:
            return state;
    }
}
