import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Discounts
//Get Discounts
export function* getDiscountsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getDiscountsRequest, payload);
        yield put(ACTIONS.getDiscountsReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Discount
export function* getDiscountSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getDiscountRequest, payload);
        yield put(ACTIONS.getDiscountReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Discount
export function* createDiscountSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createDiscountRequest, payload);
        yield put(ACTIONS.createDiscountReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Discount
export function* updateDiscountSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateDiscountRequest, payload);
        yield put(ACTIONS.updateDiscountReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Discount
export function* deleteDiscountSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteDiscountRequest, id);
        yield put(ACTIONS.deleteDiscountReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* discountsSagas() {
    yield takeLatest(TYPES.GET_DISCOUNTS_REQUEST, getDiscountsSaga);
    yield takeLatest(TYPES.GET_DISCOUNT_REQUEST, getDiscountSaga);
    yield takeLatest(TYPES.CREATE_DISCOUNT_REQUEST, createDiscountSaga);
    yield takeLatest(TYPES.UPDATE_DISCOUNT_REQUEST, updateDiscountSaga);
    yield takeLatest(TYPES.DELETE_DISCOUNT_REQUEST, deleteDiscountSaga);
}
