import * as TYPES from './types';
//Variables
// Get Variables
export const getVariablesRequest = (payload) => ({
    type: TYPES.GET_VARIABLES_REQUEST,
    payload
});
export const getVariablesReceive = (payload) => ({
    type: TYPES.GET_VARIABLES_RECEIVE,
    payload
});
//Get Variable
export const getVariableRequest = (payload) => ({
    type: TYPES.GET_VARIABLE_REQUEST,
    payload
});
export const getVariableReceive = (payload) => ({
    type: TYPES.GET_VARIABLE_RECEIVE,
    payload
});
//Create Variable
export const createVariableRequest = (payload) => ({
    type: TYPES.CREATE_VARIABLE_REQUEST,
    payload
});
export const createVariableReceive = (payload) => ({
    type: TYPES.CREATE_VARIABLE_RECEIVE,
    payload
});
//Update Variable
export const updateVariableRequest = (payload) => ({
    type: TYPES.UPDATE_VARIABLE_REQUEST,
    payload
});
export const updateVariableReceive = (payload) => ({
    type: TYPES.UPDATE_VARIABLE_RECEIVE,
    payload
});
//Delete Pre Order
export const deleteVariableRequest = (id) => ({
    type: TYPES.DELETE_VARIABLE_REQUEST,
    id
});
export const deleteVariableReceive = (id) => ({
    type: TYPES.DELETE_VARIABLE_RECEIVE,
    id
});
