import * as TYPES from './types';
//Customers
// Get Customers
export const getCustomersRequest = (payload) => ({
    type: TYPES.GET_CUSTOMERS_REQUEST,
    payload
});
export const getCustomersReceive = (payload) => ({
    type: TYPES.GET_CUSTOMERS_RECEIVE,
    payload
});
//Get Pre Order
export const getCustomerRequest = (payload) => ({
    type: TYPES.GET_CUSTOMER_REQUEST,
    payload
});
export const getCustomerReceive = (payload) => ({
    type: TYPES.GET_CUSTOMER_RECEIVE,
    payload
});
//Create Pre Order
export const createCustomerRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_CUSTOMER_REQUEST,
    payload,
    closeModal
});
export const createCustomerReceive = (payload) => ({
    type: TYPES.CREATE_CUSTOMER_RECEIVE,
    payload
});
//Update Pre Order
export const updateCustomerRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_CUSTOMER_REQUEST,
    payload,
    closeModal
});
export const updateCustomerReceive = (payload) => ({
    type: TYPES.UPDATE_CUSTOMER_RECEIVE,
    payload
});
//Delete Pre Order
export const deleteCustomerRequest = (id) => ({
    type: TYPES.DELETE_CUSTOMER_REQUEST,
    id
});
export const deleteCustomerReceive = (id) => ({
    type: TYPES.DELETE_CUSTOMER_RECEIVE,
    id
});
//Get Customers List
export const getCustomersListRequest = (payload) => ({
    type: TYPES.GET_CUSTOMERS_LIST_REQUEST,
    payload
});
export const getCustomersListReceive = (payload) => ({
    type: TYPES.GET_CUSTOMERS_LIST_RECEIVE,
    payload
});
