import * as TYPES from './types';

const initialState = {
    options: [],
    option: {}
};

export default function optionsReducer(state = initialState, action) {
    switch (action.type) {
        //Get options
        case TYPES.GET_OPTIONS_RECEIVE: {
            return {
                ...state,
                options: action.payload.data
            };
        }
        //Get option
        case TYPES.GET_OPTION_RECEIVE: {
            return {
                ...state,
                option: action.payload
            };
        }
        //Create option
        case TYPES.CREATE_OPTION_RECEIVE: {
            console.log(action.payload);
            return {
                ...state,
                options: [...state.options, action.payload]
            };
        }
        //Update option
        case TYPES.UPDATE_OPTION_RECEIVE: {
            return {
                ...state,
                options: state.options.map((option) => {
                    if (option.id === action.payload.id) {
                        return action.payload;
                    }
                    return option;
                })
            };
        }
        //Delete option
        case TYPES.DELETE_OPTION_RECEIVE: {
            return {
                ...state,
                options: state.options.filter((option) => option.id !== action.id)
            };
        }
        default:
            return state;
    }
}
