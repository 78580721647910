import * as TYPES from './types';

const initialState = {
    requests: [],
    request: {},
    requestsStatistics: {},
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    requestsList: [],
    discountCodeVerified: false,
    discountCode: {}
};

export default function requestsReducer(state = initialState, action) {
    switch (action.type) {
        //Get requests
        case TYPES.GET_REQUESTS_RECEIVE: {
            return {
                ...state,
                requests: action.payload?.data?.data,
                pageCount: action.payload?.data?.total / 10,
                totalFiltred: action.payload?.data?.total,
                currentPage: action.payload?.data?.page
            };
        }
        //Get requestData
        case TYPES.GET_REQUEST_RECEIVE: {
            return {
                ...state,
                request: action.payload
            };
        }
        //Add request
        case TYPES.ADD_REQUEST_RECEIVE: {
            return {
                ...state,
                requests: [...state.requests, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit request
        case TYPES.EDIT_REQUEST_RECEIVE: {
            return {
                ...state,
                request: action.payload,
                requests: state.requests.map((request) => {
                    if (request.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return request;
                    }
                })
            };
        }
        //Delete request
        case TYPES.DELETE_REQUEST_RECEIVE: {
            return {
                ...state,
                requests: state.requests.filter((request) => request.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        //Get requests List
        case TYPES.GET_REQUESTS_LIST_RECEIVE: {
            return {
                ...state,
                requestsList: action.payload
            };
        }

        //Get requests Statistics
        case TYPES.GET_REQUESTS_STATISTICS_RECEIVE: {
            return {
                ...state,
                requestsStatistics: action.payload
            };
        }
        //Affect professional
        case TYPES.AFFECT_PROFESSIONAL_RECEIVE: {
            return {
                ...state,
                request: action.payload,
                requests: state.requests.map((request) => {
                    if (request.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return request;
                    }
                })
            };
        }
        //check discount code
        case TYPES.CHECK_DISCOUNT_CODE_RECEIVE: {
            if (action.payload === false) {
                return {
                    ...state,
                    discountCodeVerified: false
                };
            } else {
                return {
                    ...state,
                    discountCodeVerified: true,
                    discountCode: action.payload
                };
            }
        }
        default:
            return state;
    }
}
