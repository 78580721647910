//Get Clients
export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_RECEIVE = 'GET_CLIENTS_RECEIVE';
//Get Client
export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_RECEIVE = 'GET_CLIENT_RECEIVE';
//Add Client
export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST';
export const ADD_CLIENT_RECEIVE = 'ADD_CLIENT_RECEIVE';
//Edit Client
export const EDIT_CLIENT_REQUEST = 'EDIT_CLIENT_REQUEST';
export const EDIT_CLIENT_RECEIVE = 'EDIT_CLIENT_RECEIVE';
//Delete Client
export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_RECEIVE = 'DELETE_CLIENT_RECEIVE';
//Get Clients List
export const GET_CLIENTS_LIST_REQUEST = 'GET_CLIENTS_LIST_REQUEST';
export const GET_CLIENTS_LIST_RECEIVE = 'GET_CLIENTS_LIST_RECEIVE';
//Get Clients Statistics
export const GET_CLIENTS_STATISTICS_REQUEST = 'GET_CLIENTS_STATISTICS_REQUEST';
export const GET_CLIENTS_STATISTICS_RECEIVE = 'GET_CLIENTS_STATISTICS_RECEIVE';
