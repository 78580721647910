 
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Discounts from 'views/pages/discounts/Discounts';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Requests = Loadable(lazy(() => import('views/pages/requests/Requests')));
const Projects = Loadable(lazy(() => import('views/pages/projects/Projects')));
const Payments = Loadable(lazy(() => import('views/pages/payments/Payments')));
const Tools = Loadable(lazy(() => import('views/pages/tools/Tools')));

//Users routing
const InternalUsers = Loadable(lazy(() => import('views/pages/users/InternalUsers/InternalUsers')));
const Professionals = Loadable(lazy(() => import('views/pages/users/Professionals/Professionals')));
const Clients = Loadable(lazy(() => import('views/pages/users/Clients/Clients')));

// Settings Routes
const EventsPage = Loadable(lazy(() => import('views/pages/events/EventsPage')));
const Settings = Loadable(lazy(() => import('views/pages/settings')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            element: <DashboardDefault />
        },
        {
            path: 'requests',
            element: <Requests />
        },
        {
            path: 'projects',
            element: <Projects />
        },
        {
            path: 'payments',
            element: <Payments />
        },
        {
            path: 'tools',
            element: <Tools />
        },
        {
            path: 'discounts',
            element: <Discounts />
        },
        {
            path: 'users',
            children: [
                {
                    path: 'internals',
                    element: <InternalUsers />
                },
                {
                    path: 'professionals',
                    element: <Professionals />
                },
                {
                    path: 'clients',
                    element: <Clients />
                }
            ]
        },
        {
            path: 'events',
            element: <EventsPage />
        },
        {
            path: 'settings',
            element: <Settings />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
