import * as TYPES from './types';
//Professionals
// Get Professionals
export const getProfessionalsRequest = (payload) => ({
    type: TYPES.GET_PROFESSIONALS_REQUEST,
    payload
});
export const getProfessionalsReceive = (payload) => ({
    type: TYPES.GET_PROFESSIONALS_RECEIVE,
    payload
});
//Get Pre Order
export const getProfessionalRequest = (payload) => ({
    type: TYPES.GET_PROFESSIONAL_REQUEST,
    payload
});
export const getProfessionalReceive = (payload) => ({
    type: TYPES.GET_PROFESSIONAL_RECEIVE,
    payload
});
//Create Pre Order
export const createProfessionalRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_PROFESSIONAL_REQUEST,
    payload,
    closeModal
});
export const createProfessionalReceive = (payload) => ({
    type: TYPES.CREATE_PROFESSIONAL_RECEIVE,
    payload
});
//Update Pre Order
export const updateProfessionalRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_PROFESSIONAL_REQUEST,
    payload,
    closeModal
});
export const updateProfessionalReceive = (payload) => ({
    type: TYPES.UPDATE_PROFESSIONAL_RECEIVE,
    payload
});
//Delete Pre Order
export const deleteProfessionalRequest = (id) => ({
    type: TYPES.DELETE_PROFESSIONAL_REQUEST,
    id
});
export const deleteProfessionalReceive = (id) => ({
    type: TYPES.DELETE_PROFESSIONAL_RECEIVE,
    id
});
// Get Professionals List
export const getProfessionalsListRequest = (payload) => ({
    type: TYPES.GET_PROFESSIONALS_LIST_REQUEST,
    payload
});
export const getProfessionalsListReceive = (payload) => ({
    type: TYPES.GET_PROFESSIONALS_LIST_RECEIVE,
    payload
});
// Active Professional Account
export const activeProfessionalAccountRequest = (payload) => ({
    type: TYPES.ACTIVE_PROFESSIONAL_ACCOUNT_REQUEST,
    payload
});
export const activeProfessionalAccountReceive = (payload) => ({
    type: TYPES.ACTIVE_PROFESSIONAL_ACCOUNT_RECEIVE,
    payload
});
