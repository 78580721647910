//Customers
//Get Customers
export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_RECEIVE = 'GET_CUSTOMERS_RECEIVE';
//Get Customer
export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_RECEIVE = 'GET_CUSTOMER_RECEIVE';
//Create Customer
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_RECEIVE = 'CREATE_CUSTOMER_RECEIVE';
//Update Customer
export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_RECEIVE = 'UPDATE_CUSTOMER_RECEIVE';
//Delete Customer
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_RECEIVE = 'DELETE_CUSTOMER_RECEIVE';
//Get Customers List
export const GET_CUSTOMERS_LIST_REQUEST = 'GET_CUSTOMERS_LIST_REQUEST';
export const GET_CUSTOMERS_LIST_RECEIVE = 'GET_CUSTOMERS_LIST_RECEIVE';
