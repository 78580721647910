/* eslint-disable react/prop-types */
 
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Avatar, ButtonBase, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconEdit, IconTrash } from '@tabler/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FormatPrice } from 'utils/PriceFormatter';

const columns = [
    {
        id: 'code',
        label: 'Code',
        align: 'left',
        minWidth: 150
    },
    {
        id: 'value',
        label: 'Valeur',
        align: 'left',
        minWidth: 100
    },
    {
        id: 'expirationDate',
        label: "Date d'expiration",
        align: 'left',
        minWidth: 150
    },
    {
        id: 'actions',
        label: 'Actions',
        minWidth: 120,
        align: 'center'
    }
];

export function TableContainerComponent({
    page,
    totalFiltred,
    limit,
    setLimit,
    setPage,
    discounts,
    selectDiscount,
    openForm,
    openDeleteConfirmation
}) {
    const theme = useTheme();
    const { loading } = useSelector((state) => state.loading);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(event.target.value);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell align="center" colSpan={columns.length}>
                                    <span className="api_loading">
                                        <img src="/loading.gif" width={50} alt="loader" />
                                    </span>
                                </TableCell>
                            </TableRow>
                        ) : discounts?.length > 0 ? (
                            discounts?.map((row) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{
                                        backgroundColor:
                                            new Date(row.expirationDate) > new Date()
                                                ? theme.palette.success.light
                                                : theme.palette.warning.light
                                    }}
                                >
                                    <TableCell align="left">
                                        <Typography variant="subtitle2">{row.code}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="subtitle2">{FormatPrice(row.value)}</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="subtitle2">{moment(row.expirationDate).format('DD/MM/YYYY HH:mm')}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', marginRight: '5px' }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.mediumAvatar,
                                                    transition: 'all .2s ease-in-out',
                                                    background: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    '&:hover': {
                                                        background: theme.palette.secondary.dark,
                                                        color: theme.palette.secondary.light
                                                    }
                                                }}
                                                onClick={() => {
                                                    selectDiscount(row);
                                                    openForm();
                                                }}
                                                color="inherit"
                                            >
                                                <IconEdit stroke={1.5} size="1.3rem" />
                                            </Avatar>
                                        </ButtonBase>
                                        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.mediumAvatar,
                                                    transition: 'all .2s ease-in-out',
                                                    background: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    '&:hover': {
                                                        background: theme.palette.error.dark,
                                                        color: theme.palette.error.light
                                                    }
                                                }}
                                                onClick={() => {
                                                    selectDiscount(row);
                                                    openDeleteConfirmation();
                                                }}
                                                color="inherit"
                                            >
                                                <IconTrash stroke={1.5} size="1.3rem" />
                                            </Avatar>
                                        </ButtonBase>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan="5" align="center" sx={{ padding: '80px 0' }}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Aucun Evennements trouvé
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalFiltred || 0}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

TableContainerComponent.propTypes = {
    discounts: PropTypes.array,
    selectDiscount: PropTypes.func.isRequired,
    openForm: PropTypes.func.isRequired,
    openDeleteConfirmation: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    totalFiltred: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    setLimit: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired
};

export default TableContainerComponent;
