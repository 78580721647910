import { axiosInstance } from '../../network';
//Professionals
//Get Professionals
export const getProfessionalsRequest = async (payload) => {
    return await axiosInstance.get(`api/professionals`, { params: payload });
};
//Get Professional
export const getProfessionalRequest = async (payload) => {
    return await axiosInstance.get(`api/professionals/${payload}`);
};
//Create Professional
export const createProfessionalRequest = async (payload) => {
    return await axiosInstance.post(`api/professionals`, payload);
};
//Update Professional
export const updateProfessionalRequest = async (payload) => {
    return await axiosInstance.patch(`api/professionals/${payload.id}`, payload);
};
//Delete Professional
export const deleteProfessionalRequest = async (id) => {
    return await axiosInstance.delete(`api/professionals/${id}`);
};
// Get Professionals List
export const getProfessionalsListRequest = async (payload) => {
    return await axiosInstance.get(`api/professionals/list`, { params: payload });
};
// Active Professional Account
export const activeProfessionalAccountRequest = async (payload) => {
    return await axiosInstance.patch(`api/professionals/active-account/${payload}`);
};
