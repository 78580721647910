 
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Button, ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import TableContainerComponent from './TableContainerComponent';
import { useState } from 'react';
import FilterContainer from './FilterContainer';
import { useTheme } from '@mui/material/styles';
import { IconPlus } from '@tabler/icons';
import CreateContainer from './CreateContainer';
import EditContainer from './EditContainer';
import { deleteDiscountRequest, getDiscountsRequest } from 'store/discounts/actions';

function Discounts() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const { discounts, totalFiltred } = useSelector((state) => state.discounts);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const [payload, setPayload] = useState({
        page: 0,
        limit: 10,
        search: ''
    });

    useEffect(() => {
        dispatch(getDiscountsRequest(payload));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload]);

    const handleFilter = (data) => {
        setPayload({
            ...payload,
            search: data.search
        });
    };

    const handleDelete = () => {
        dispatch(deleteDiscountRequest(selectedDiscount.id));
        setOpenDeleteConfirmation(false);
    };

    return (
        <div>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={4}>
                    <Box display="flex" alignItems="center">
                        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', marginRight: '5px' }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    transition: 'all .2s ease-in-out',
                                    background: theme.palette.success.light,
                                    color: theme.palette.success.dark,
                                    '&:hover': {
                                        background: theme.palette.success.dark,
                                        color: theme.palette.success.light
                                    }
                                }}
                                onClick={() => {
                                    setSelectedDiscount(null);
                                    setOpenForm(true);
                                }}
                                color="inherit"
                            >
                                <IconPlus stroke={1.5} size="1.3rem" />
                            </Avatar>
                        </ButtonBase>
                        <Typography variant="h2" sx={{ marginLeft: '10px', marginBottom: 0 }} gutterBottom>
                            {discounts?.length || 0} Codes de promotions
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <FilterContainer payload={payload} setPayload={(data) => handleFilter(data)} />
                </Grid>
            </Grid>
            <TableContainerComponent
                page={payload.page}
                setPage={(data) => setPayload({ ...payload, page: data })}
                limit={payload.limit}
                setLimit={(data) => setPayload({ ...payload, limit: parseInt(data), page: 0 })}
                totalFiltred={totalFiltred || 0}
                discounts={discounts}
                selectDiscount={(data) => setSelectedDiscount(data)}
                openForm={() => setOpenForm(true)}
                openDeleteConfirmation={() => setOpenDeleteConfirmation(true)}
            />
            {openForm && !selectedDiscount && <CreateContainer open={openForm} close={() => setOpenForm(false)} />}
            {openForm && selectedDiscount && <EditContainer open={openForm} close={() => setOpenForm(false)} discount={selectedDiscount} />}
            {openDeleteConfirmation && (
                <Dialog open={openDeleteConfirmation} onClose={() => setOpenDeleteConfirmation(false)}>
                    <DialogTitle sx={{ padding: '20px' }}>Supprimer le code</DialogTitle>
                    <DialogContent sx={{ padding: '20px' }}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Voulez-vous vraiment supprimer le code <b>{selectedDiscount.code}</b> ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeleteConfirmation(false)} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => handleDelete()} color="error">
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}

export default Discounts;
