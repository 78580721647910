import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Options
//Get Options
export function* getOptionsSaga({ payload }) {
    try {
        //yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getOptionsRequest, payload);
        yield put(ACTIONS.getOptionsReceive({ data }));
        //yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Option
export function* getOptionSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getOptionRequest, payload);
        yield put(ACTIONS.getOptionReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Option
export function* createOptionSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createOptionRequest, payload);
        yield put(ACTIONS.createOptionReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Option
export function* updateOptionSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateOptionRequest, payload);
        yield put(ACTIONS.updateOptionReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Option
export function* deleteOptionSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteOptionRequest, id);
        yield put(ACTIONS.deleteOptionReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* optionsSagas() {
    yield takeLatest(TYPES.GET_OPTIONS_REQUEST, getOptionsSaga);
    yield takeLatest(TYPES.GET_OPTION_REQUEST, getOptionSaga);
    yield takeLatest(TYPES.CREATE_OPTION_REQUEST, createOptionSaga);
    yield takeLatest(TYPES.UPDATE_OPTION_REQUEST, updateOptionSaga);
    yield takeLatest(TYPES.DELETE_OPTION_REQUEST, deleteOptionSaga);
}
