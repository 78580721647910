import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Customers
//Get Customers
export function* getCustomersSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getCustomersRequest, payload);
        yield put(ACTIONS.getCustomersReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Customer
export function* getCustomerSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getCustomerRequest, payload);
        yield put(ACTIONS.getCustomerReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Customer
export function* createCustomerSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createCustomerRequest, payload);
        yield put(ACTIONS.createCustomerReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Customer
export function* updateCustomerSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateCustomerRequest, payload);
        yield put(ACTIONS.updateCustomerReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Customer
export function* deleteCustomerSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteCustomerRequest, id);
        yield put(ACTIONS.deleteCustomerReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Customers List
export function* getCustomersListSaga({ payload }) {
    try {
        const { data } = yield call(APIS.getCustomersListRequest, payload);
        yield put(ACTIONS.getCustomersListReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* customersSagas() {
    yield takeLatest(TYPES.GET_CUSTOMERS_REQUEST, getCustomersSaga);
    yield takeLatest(TYPES.GET_CUSTOMER_REQUEST, getCustomerSaga);
    yield takeLatest(TYPES.CREATE_CUSTOMER_REQUEST, createCustomerSaga);
    yield takeLatest(TYPES.UPDATE_CUSTOMER_REQUEST, updateCustomerSaga);
    yield takeLatest(TYPES.DELETE_CUSTOMER_REQUEST, deleteCustomerSaga);
    yield takeLatest(TYPES.GET_CUSTOMERS_LIST_REQUEST, getCustomersListSaga);
}
