import * as TYPES from './types';
//Options
// Get Options
export const getOptionsRequest = (payload) => ({
    type: TYPES.GET_OPTIONS_REQUEST,
    payload
});
export const getOptionsReceive = (payload) => ({
    type: TYPES.GET_OPTIONS_RECEIVE,
    payload
});
//Get Option
export const getOptionRequest = (payload) => ({
    type: TYPES.GET_OPTION_REQUEST,
    payload
});
export const getOptionReceive = (payload) => ({
    type: TYPES.GET_OPTION_RECEIVE,
    payload
});
//Create Option
export const createOptionRequest = (payload) => ({
    type: TYPES.CREATE_OPTION_REQUEST,
    payload
});
export const createOptionReceive = (payload) => ({
    type: TYPES.CREATE_OPTION_RECEIVE,
    payload
});
//Update Option
export const updateOptionRequest = (payload) => ({
    type: TYPES.UPDATE_OPTION_REQUEST,
    payload
});
export const updateOptionReceive = (payload) => ({
    type: TYPES.UPDATE_OPTION_RECEIVE,
    payload
});
//Delete Option
export const deleteOptionRequest = (id) => ({
    type: TYPES.DELETE_OPTION_REQUEST,
    id
});
export const deleteOptionReceive = (id) => ({
    type: TYPES.DELETE_OPTION_RECEIVE,
    id
});
