 
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from './FormContainer';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createDiscountRequest } from 'store/discounts/actions';
import { initialDiscount } from 'utils/Constants';
import moment from 'moment';

function CreateContainer({ open, close }) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(initialDiscount);

    const handleSetFormData = (e) => {
        if (e.target.name === 'value') {
            setFormData({
                ...formData,
                value: e.target?.value?.length > 0 && parseInt(e.target.value)
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const closeModal = () => {
        setFormData(initialDiscount);
        close();
    };

    const handleCreate = () => {
        dispatch(createDiscountRequest(formData, closeModal));
    };

    return (
        <Dialog open={open} onClose={close} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <DialogTitle id="modal-modal-title" sx={{ padding: '40px', fontSize: '20px' }}>
                Créer Code Promo
            </DialogTitle>
            <DialogContent sx={{ padding: '40px' }}>
                <FormContainer formData={formData} handleSetFormData={(e) => handleSetFormData(e)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="primary">
                    Annuler
                </Button>
                <Button onClick={handleCreate} variant="secondary">
                    Créer
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CreateContainer.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
};

export default CreateContainer;
