 
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from './FormContainer';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { updateDiscountRequest } from 'store/discounts/actions';
import { initialDiscount } from 'utils/Constants';

function EditContainer({ open, close, discount }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialDiscount);

    useEffect(() => {
        if (discount) {
            setFormData(discount);
        }
    }, [discount]);

    const handleSetFormData = (e) => {
        if (e.target.name === 'value') {
            setFormData({
                ...formData,
                value: e.target?.value && parseInt(e.target.value)
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const closeModal = () => {
        setFormData(initialDiscount);
        close();
    };

    const handleUpdate = () => {
        dispatch(updateDiscountRequest(formData, closeModal));
        close();
    };

    return (
        <Dialog open={open} onClose={close} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <DialogTitle id="modal-modal-title" sx={{ padding: '40px', fontSize: '20px' }}>
                Modifier Code Promo
            </DialogTitle>
            <DialogContent sx={{ padding: '40px' }}>
                <FormContainer formData={formData} handleSetFormData={(e) => handleSetFormData(e)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={close} variant="primary">
                    Annuler
                </Button>
                <Button onClick={handleUpdate} variant="secondary">
                    Modifier
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EditContainer.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    discount: PropTypes.object
};

export default EditContainer;
