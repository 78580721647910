import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Professionals
//Get Professionals
export function* getProfessionalsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getProfessionalsRequest, payload);
        yield put(ACTIONS.getProfessionalsReceive({ data }));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Professional
export function* getProfessionalSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getProfessionalRequest, payload);
        yield put(ACTIONS.getProfessionalReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Professional
export function* createProfessionalSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createProfessionalRequest, payload);
        yield put(ACTIONS.createProfessionalReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Professional
export function* updateProfessionalSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateProfessionalRequest, payload);
        yield put(ACTIONS.updateProfessionalReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Professional
export function* deleteProfessionalSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteProfessionalRequest, id);
        yield put(ACTIONS.deleteProfessionalReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

// Get Professionals List
export function* getProfessionalsListSaga({ payload }) {
    try {
        const { data } = yield call(APIS.getProfessionalsListRequest, payload);
        yield put(ACTIONS.getProfessionalsListReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

// Active Professional Account
export function* activeProfessionalAccountSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.activeProfessionalAccountRequest, payload);
        yield put(ACTIONS.activeProfessionalAccountReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* professionalsSagas() {
    yield takeLatest(TYPES.GET_PROFESSIONALS_REQUEST, getProfessionalsSaga);
    yield takeLatest(TYPES.GET_PROFESSIONAL_REQUEST, getProfessionalSaga);
    yield takeLatest(TYPES.CREATE_PROFESSIONAL_REQUEST, createProfessionalSaga);
    yield takeLatest(TYPES.UPDATE_PROFESSIONAL_REQUEST, updateProfessionalSaga);
    yield takeLatest(TYPES.DELETE_PROFESSIONAL_REQUEST, deleteProfessionalSaga);
    yield takeLatest(TYPES.GET_PROFESSIONALS_LIST_REQUEST, getProfessionalsListSaga);
    yield takeLatest(TYPES.ACTIVE_PROFESSIONAL_ACCOUNT_REQUEST, activeProfessionalAccountSaga);
}
