//Get Requests
export const GET_REQUESTS_REQUEST = 'GET_REQUESTS_REQUEST';
export const GET_REQUESTS_RECEIVE = 'GET_REQUESTS_RECEIVE';
//Get RequestData
export const GET_REQUEST_REQUEST = 'GET_REQUEST_REQUEST';
export const GET_REQUEST_RECEIVE = 'GET_REQUEST_RECEIVE';
//Add Request
export const ADD_REQUEST_REQUEST = 'ADD_REQUEST_REQUEST';
export const ADD_REQUEST_RECEIVE = 'ADD_REQUEST_RECEIVE';
//Edit Request
export const EDIT_REQUEST_REQUEST = 'EDIT_REQUEST_REQUEST';
export const EDIT_REQUEST_RECEIVE = 'EDIT_REQUEST_RECEIVE';
//Delete Request
export const DELETE_REQUEST_REQUEST = 'DELETE_REQUEST_REQUEST';
export const DELETE_REQUEST_RECEIVE = 'DELETE_REQUEST_RECEIVE';
//Get Requests List
export const GET_REQUESTS_LIST_REQUEST = 'GET_REQUESTS_LIST_REQUEST';
export const GET_REQUESTS_LIST_RECEIVE = 'GET_REQUESTS_LIST_RECEIVE';
//Get Requests Statistics
export const GET_REQUESTS_STATISTICS_REQUEST = 'GET_REQUESTS_STATISTICS_REQUEST';
export const GET_REQUESTS_STATISTICS_RECEIVE = 'GET_REQUESTS_STATISTICS_RECEIVE';
//Affect Proffessional
export const AFFECT_PROFESSIONAL_REQUEST = 'AFFECT_PROFESSIONAL_REQUEST';
export const AFFECT_PROFESSIONAL_RECEIVE = 'AFFECT_PROFESSIONAL_RECEIVE';
//Check discount code
export const CHECK_DISCOUNT_CODE_REQUEST = 'CHECK_DISCOUNT_CODE_REQUEST';
export const CHECK_DISCOUNT_CODE_RECEIVE = 'CHECK_DISCOUNT_CODE_RECEIVE';
