//Discounts
//Get Discounts
export const GET_DISCOUNTS_REQUEST = 'GET_DISCOUNTS_REQUEST';
export const GET_DISCOUNTS_RECEIVE = 'GET_DISCOUNTS_RECEIVE';
//Get Discount
export const GET_DISCOUNT_REQUEST = 'GET_DISCOUNT_REQUEST';
export const GET_DISCOUNT_RECEIVE = 'GET_DISCOUNT_RECEIVE';
//Create Discount
export const CREATE_DISCOUNT_REQUEST = 'CREATE_DISCOUNT_REQUEST';
export const CREATE_DISCOUNT_RECEIVE = 'CREATE_DISCOUNT_RECEIVE';
//Update Discount
export const UPDATE_DISCOUNT_REQUEST = 'UPDATE_DISCOUNT_REQUEST';
export const UPDATE_DISCOUNT_RECEIVE = 'UPDATE_DISCOUNT_RECEIVE';
//Delete Discount
export const DELETE_DISCOUNT_REQUEST = 'DELETE_DISCOUNT_REQUEST';
export const DELETE_DISCOUNT_RECEIVE = 'DELETE_DISCOUNT_RECEIVE';
