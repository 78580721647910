import { combineReducers } from 'redux';
import theme from './theme/reducer';
import auth from './auth/reducer';
import users from './users/reducer';
import loader from './loader/reducer';
import snackbar from './snackbar/reducer';
import notifications from './notifications/reducer';
import events from './events/reducer';
import services from './services/reducer';
import payments from './payments/reducer';
import comments from './comments/reducer';
import requests from './requests/reducer';
import options from './options/reducer';
import tools from './tools/reducer';
import professionals from './professionals/reducer';
import customers from './customers/reducer';
import projects from './projects/reducer';
import discounts from './discounts/reducer';
import variables from './variables/reducer';
import loading from './loading/reducer';
import dashboard from './dashboard/reducer';

export default combineReducers({
    theme,
    snackbar,
    loader,
    auth,
    users,
    notifications,
    events,
    services,
    payments,
    comments,
    requests,
    options,
    professionals,
    customers,
    tools,
    projects,
    discounts,
    variables,
    loading,
    dashboard
});
