//Services
//Get Services
export const GET_VARIABLES_REQUEST = 'GET_VARIABLES_REQUEST';
export const GET_VARIABLES_RECEIVE = 'GET_VARIABLES_RECEIVE';
//Get Service
export const GET_VARIABLE_REQUEST = 'GET_VARIABLE_REQUEST';
export const GET_VARIABLE_RECEIVE = 'GET_VARIABLE_RECEIVE';
//Create Service
export const CREATE_VARIABLE_REQUEST = 'CREATE_VARIABLE_REQUEST';
export const CREATE_VARIABLE_RECEIVE = 'CREATE_VARIABLE_RECEIVE';
//Update Service
export const UPDATE_VARIABLE_REQUEST = 'UPDATE_VARIABLE_REQUEST';
export const UPDATE_VARIABLE_RECEIVE = 'UPDATE_VARIABLE_RECEIVE';
//Delete Service
export const DELETE_VARIABLE_REQUEST = 'DELETE_VARIABLE_REQUEST';
export const DELETE_VARIABLE_RECEIVE = 'DELETE_VARIABLE_RECEIVE';
