import { axiosInstance } from '../../network';

//Get Clients
export const getClientsRequest = async (payload) => {
    return await axiosInstance.get(`api/clients`, { params: payload });
};
//Get ClientData
export const getClientRequest = async (id) => {
    return await axiosInstance.get(`api/clients/${id}`);
};
//Add Client
export const addClientRequest = async (payload) => {
    return await axiosInstance.post(`api/clients`, payload);
};
//Edit Client
export const editClientRequest = async (payload) => {
    return await axiosInstance.patch(`api/clients/${payload.id}`, payload);
};
//Delete Client
export const deleteClientRequest = async (id) => {
    return await axiosInstance.delete(`api/clients/${id}`);
};
//Get Clients List
export const getClientsListRequest = async (payload) => {
    return await axiosInstance.get(`api/clients/list`, { params: payload });
};
//Get Clients Statistics
export const getClientsStatisticsRequest = async (payload) => {
    return await axiosInstance.get(`api/clients/statistics`, { params: payload });
};
