import { axiosInstance } from '../../network';

//Get Requests
export const getRequestsRequest = async (payload) => {
    return await axiosInstance.get(`api/requests`, { params: payload });
};
//Get Request
export const getRequestRequest = async (id) => {
    return await axiosInstance.get(`api/requests/${id}`);
};
//Add Request
export const addRequestRequest = async (payload) => {
    return await axiosInstance.post(`api/requests`, payload);
};
//Edit Request
export const editRequestRequest = async (payload) => {
    return await axiosInstance.patch(`api/requests/${payload.id}`, payload);
};
//Delete Request
export const deleteRequestRequest = async (id) => {
    return await axiosInstance.delete(`api/requests/${id}`);
};
//Get Requests List
export const getRequestsListRequest = async (payload) => {
    return await axiosInstance.get(`api/requests/list`, { params: payload });
};
//Get Requests Statistics
export const getRequestsStatisticsRequest = async (payload) => {
    return await axiosInstance.get(`api/requests/statistics`, { params: payload });
};
// Affect Professional
export const affectProfessionalRequest = async (payload) => {
    return await axiosInstance.put(`api/requests/${payload.requestId}/affect-professional`, payload);
};
//Check Discount Code
export const checkDiscountCodeRequest = async (payload) => {
    return await axiosInstance.get(`api/requests/check-discount-code`, { params: { code: payload } });
};
