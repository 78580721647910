import * as TYPES from './types';
//Projects
// Get Projects
export const getProjectsRequest = (payload) => ({
    type: TYPES.GET_PROJECTS_REQUEST,
    payload
});
export const getProjectsReceive = (payload) => ({
    type: TYPES.GET_PROJECTS_RECEIVE,
    payload
});
//Get Project
export const getProjectRequest = (payload) => ({
    type: TYPES.GET_PROJECT_REQUEST,
    payload
});
export const getProjectReceive = (payload) => ({
    type: TYPES.GET_PROJECT_RECEIVE,
    payload
});
//Create Project
export const createProjectRequest = (payload) => ({
    type: TYPES.CREATE_PROJECT_REQUEST,
    payload
});
export const createProjectReceive = (payload) => ({
    type: TYPES.CREATE_PROJECT_RECEIVE,
    payload
});
//Update Project
export const updateProjectRequest = (payload) => ({
    type: TYPES.UPDATE_PROJECT_REQUEST,
    payload
});
export const updateProjectReceive = (payload) => ({
    type: TYPES.UPDATE_PROJECT_RECEIVE,
    payload
});
//Delete Project
export const deleteProjectRequest = (id) => ({
    type: TYPES.DELETE_PROJECT_REQUEST,
    id
});
export const deleteProjectReceive = (id) => ({
    type: TYPES.DELETE_PROJECT_RECEIVE,
    id
});
