import * as TYPES from './types';
//Services
// Get Services
export const getServicesRequest = (payload) => ({
    type: TYPES.GET_SERVICES_REQUEST,
    payload
});
export const getServicesReceive = (payload) => ({
    type: TYPES.GET_SERVICES_RECEIVE,
    payload
});
//Get service
export const getServiceRequest = (payload) => ({
    type: TYPES.GET_SERVICE_REQUEST,
    payload
});
export const getServiceReceive = (payload) => ({
    type: TYPES.GET_SERVICE_RECEIVE,
    payload
});
//Create service
export const createServiceRequest = (payload) => ({
    type: TYPES.CREATE_SERVICE_REQUEST,
    payload
});
export const createServiceReceive = (payload) => ({
    type: TYPES.CREATE_SERVICE_RECEIVE,
    payload
});
//Update service
export const updateServiceRequest = (payload) => ({
    type: TYPES.UPDATE_SERVICE_REQUEST,
    payload
});
export const updateServiceReceive = (payload) => ({
    type: TYPES.UPDATE_SERVICE_RECEIVE,
    payload
});
//Delete Pre Order
export const deleteServiceRequest = (id) => ({
    type: TYPES.DELETE_SERVICE_REQUEST,
    id
});
export const deleteServiceReceive = (id) => ({
    type: TYPES.DELETE_SERVICE_RECEIVE,
    id
});
