import * as TYPES from './types';

const initialState = {
    discounts: [],
    discount: {},
    totalFiltred: 0
};

export default function discountsReducer(state = initialState, action) {
    switch (action.type) {
        //Get discounts
        case TYPES.GET_DISCOUNTS_RECEIVE: {
            return {
                ...state,
                discounts: action.payload?.data?.data,
                pageCount: action.payload?.data?.total / 10,
                totalFiltred: action.payload?.data?.total,
                currentPage: action.payload?.data?.page
            };
        }
        //Get discount
        case TYPES.GET_DISCOUNT_RECEIVE: {
            return {
                ...state,
                discount: action.payload
            };
        }
        //Create discount
        case TYPES.CREATE_DISCOUNT_RECEIVE: {
            return {
                ...state,
                discounts: [...state.discounts, action.payload]
            };
        }
        //Update discount
        case TYPES.UPDATE_DISCOUNT_RECEIVE: {
            return {
                ...state,
                discounts: state.discounts.map((discount) => {
                    if (discount.id === action.payload.id) {
                        return action.payload;
                    }
                    return discount;
                })
            };
        }
        //Delete discount
        case TYPES.DELETE_DISCOUNT_RECEIVE: {
            return {
                ...state,
                discounts: state.discounts.filter((discount) => discount.id !== action.id)
            };
        }
        default:
            return state;
    }
}
