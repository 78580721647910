import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';

//Get Clients
export function* getClientsSaga({ payload }) {
    try {
        const { data } = yield call(APIS.getClientsRequest, payload);
        yield put(ACTIONS.getClientsReceive({ data }));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Client
export function* getClientSaga({ id }) {
    try {
        const { data } = yield call(APIS.getClientRequest, id);
        yield put(ACTIONS.getClientReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Add Client
export function* addClientSaga({ payload, navigate }) {
    try {
        const { data } = yield call(APIS.addClientRequest, payload);
        yield put(ACTIONS.addClientReceive(data));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            navigate('/Clients/internals');
        }, 1000);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit Client
export function* editClientSaga({ payload, navigate }) {
    try {
        const { data } = yield call(APIS.editClientRequest, payload);
        yield put(ACTIONS.editClientReceive(data));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            navigate('/Clients/internals');
        }, 1000);
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Client
export function* deleteClientSaga({ id }) {
    try {
        yield call(APIS.deleteClientRequest, id);
        yield put(ACTIONS.deleteClientReceive(id));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Clients List
export function* getClientsListSaga({ payload }) {
    try {
        const { data } = yield call(APIS.getClientsListRequest, payload);
        yield put(ACTIONS.getClientsListReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Clients Statistics
export function* getClientsStatisticsSaga({ payload }) {
    try {
        const { data } = yield call(APIS.getClientsStatisticsRequest, payload);
        yield put(ACTIONS.getClientsStatisticsReceive(data));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* commentsSagas() {
    yield takeLatest(TYPES.GET_CLIENTS_REQUEST, getClientsSaga);
    yield takeLatest(TYPES.GET_CLIENT_REQUEST, getClientSaga);
    yield takeLatest(TYPES.ADD_CLIENT_REQUEST, addClientSaga);
    yield takeLatest(TYPES.EDIT_CLIENT_REQUEST, editClientSaga);
    yield takeLatest(TYPES.DELETE_CLIENT_REQUEST, deleteClientSaga);
    yield takeLatest(TYPES.GET_CLIENTS_LIST_REQUEST, getClientsListSaga);
    yield takeLatest(TYPES.GET_CLIENTS_STATISTICS_REQUEST, getClientsStatisticsSaga);
}
