import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Services
//Get Services
export function* getServicesSaga({ payload }) {
    try {
        //yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getServicesRequest, payload);
        yield put(ACTIONS.getServicesReceive({ data }));
        //yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Service
export function* getServiceSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getServiceRequest, payload);
        yield put(ACTIONS.getServiceReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Create Service
export function* createServiceSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createServiceRequest, payload);
        yield put(ACTIONS.createServiceReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Update Service
export function* updateServiceSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateServiceRequest, payload);
        yield put(ACTIONS.updateServiceReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Service
export function* deleteServiceSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteServiceRequest, id);
        yield put(ACTIONS.deleteServiceReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        console.log(err?.response?.data);
        dispatchSnackbarError(err.response?.data);
    }
}

export function* servicesSagas() {
    yield takeLatest(TYPES.GET_SERVICES_REQUEST, getServicesSaga);
    yield takeLatest(TYPES.GET_SERVICE_REQUEST, getServiceSaga);
    yield takeLatest(TYPES.CREATE_SERVICE_REQUEST, createServiceSaga);
    yield takeLatest(TYPES.UPDATE_SERVICE_REQUEST, updateServiceSaga);
    yield takeLatest(TYPES.DELETE_SERVICE_REQUEST, deleteServiceSaga);
}
