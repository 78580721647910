// assets
import { IconUsers, IconCalendarEvent, IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconCalendarEvent,
    IconSettings
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const system = {
    id: 'system',
    title: 'Système',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Utilisateurs de système',
            type: 'collapse',
            url: '/users',
            icon: icons.IconUsers,
            breadcrumbs: true,
            children: [
                {
                    id: 'clients',
                    title: 'Clients',
                    type: 'item',
                    url: '/users/clients',
                    breadcrumbs: true
                },
                {
                    id: 'Professionals',
                    title: 'Professionnels',
                    type: 'item',
                    url: '/users/professionals',
                    breadcrumbs: true
                },
                {
                    id: 'internal-users',
                    title: 'Utilisateurs internes',
                    type: 'item',
                    url: '/users/internals',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'events',
            title: 'Jounral des événements',
            type: 'item',
            url: '/events',
            icon: icons.IconCalendarEvent,
            breadcrumbs: true
        },
        {
            id: 'settings',
            title: 'Paramètres',
            type: 'item',
            url: '/settings',
            icon: icons.IconSettings,
            breadcrumbs: true
        }
    ]
};

export default system;
