import * as TYPES from './types';

const initialState = {
    projects: [],
    project: {},
    totalCount: 0
};

export default function projectsReducer(state = initialState, action) {
    switch (action.type) {
        //Get projects
        case TYPES.GET_PROJECTS_RECEIVE: {
            return {
                ...state,
                projects: action.payload.data?.data,
                totalCount: action.payload.data?.total
            };
        }
        //Get project
        case TYPES.GET_PROJECT_RECEIVE: {
            return {
                ...state,
                project: action.payload
            };
        }
        //Create project
        case TYPES.CREATE_PROJECT_RECEIVE: {
            console.log(action.payload);
            return {
                ...state,
                projects: [...state.projects, action.payload]
            };
        }
        //Update project
        case TYPES.UPDATE_PROJECT_RECEIVE: {
            return {
                ...state,
                projects: state.projects.map((project) => {
                    if (project.id === action.payload.id) {
                        return action.payload;
                    }
                    return project;
                })
            };
        }
        //Delete project
        case TYPES.DELETE_PROJECT_RECEIVE: {
            return {
                ...state,
                projects: state.projects.filter((project) => project.id !== action.id)
            };
        }
        default:
            return state;
    }
}
