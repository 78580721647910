//Projects
//Get Projects
export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_RECEIVE = 'GET_PROJECTS_RECEIVE';
//Get Project
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_RECEIVE = 'GET_PROJECT_RECEIVE';
//Create Project
export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_RECEIVE = 'CREATE_PROJECT_RECEIVE';
//Update Project
export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_RECEIVE = 'UPDATE_PROJECT_RECEIVE';
//Delete Project
export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_RECEIVE = 'DELETE_PROJECT_RECEIVE';
