import { all } from 'redux-saga/effects';
import { authSagas } from './auth/sagas';
import { usersSagas } from './users/sagas';
import { notificationsSagas } from './notifications/sagas';
import { eventsSagas } from './events/sagas';
import { servicesSagas } from './services/sagas';
import { paymentsSagas } from './payments/sagas';
import { themeSagas } from './theme/sagas';
import { commentsSagas } from './comments/sagas';
import { requestsSagas } from './requests/sagas';
import { optionsSagas } from './options/sagas';
import { toolsSagas } from './tools/sagas';
import { professionalsSagas } from './professionals/sagas';
import { customersSagas } from './customers/sagas';
import { projectsSagas } from './projects/sagas';
import { discountsSagas } from './discounts/sagas';
import { variablesSagas } from './variables/sagas';
import { dashboardSagas } from './dashboard/sagas';

export function* watchSagas() {
    yield all([
        themeSagas(),
        authSagas(),
        usersSagas(),
        notificationsSagas(),
        eventsSagas(),
        servicesSagas(),
        paymentsSagas(),
        commentsSagas(),
        requestsSagas(),
        optionsSagas(),
        toolsSagas(),
        professionalsSagas(),
        customersSagas(),
        projectsSagas(),
        discountsSagas(),
        variablesSagas(),
        dashboardSagas()
    ]);
}
