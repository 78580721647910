import { axiosInstance } from '../../network';
//Customers
//Get Customers
export const getCustomersRequest = async (payload) => {
    return await axiosInstance.get(`api/customers`, { params: payload });
};
//Get Customer
export const getCustomerRequest = async (payload) => {
    return await axiosInstance.get(`api/customers/${payload}`);
};
//Create Customer
export const createCustomerRequest = async (payload) => {
    return await axiosInstance.post(`api/customers`, payload);
};
//Update Customer
export const updateCustomerRequest = async (payload) => {
    return await axiosInstance.patch(`api/customers/${payload.id}`, payload);
};
//Delete Customer
export const deleteCustomerRequest = async (id) => {
    return await axiosInstance.delete(`api/customers/${id}`);
};
//Get Customers List
export const getCustomersListRequest = async (payload) => {
    return await axiosInstance.get(`api/customers/list`, { params: payload });
};
